// React libs
import styled from "styled-components";
// Project libs/dependencies
import { SIZES } from "../../theming/variables";
// Components
import Section from "../atoms/Section";
import H1 from "../atoms/H1";
import Certification from "../molecules/Certification";

export default function Certifications({ data }) {
  return (
    <Section>
      <Wrapper>
        <H1 type="articles">Certifications</H1>
        {data.map((item, idx) => (
          <Certification data={item} key={idx} />
        ))}
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.m};
  @media screen and (max-width: 1000px) {
    gap: ${SIZES.space.s};
  }
`;
