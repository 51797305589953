// React libs
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";

export default function ProgressBar({ progress = 0 }) {
  return (
    <Bar progress={progress}>
      <Progress progress={progress} />
    </Bar>
  );
}

const Bar = styled.div`
  height: ${SIZES.space.s};
  border-radius: ${SIZES.space.s};
  background: ${COLORS.gray.three};
  width: 100%;
`;

const Progress = styled.div`
  height: inherit;
  border-radius: inherit;
  background: ${COLORS.accent};
  width: ${(props) => `${props.progress}%`};
`;
