export const COLORS = {
  bkg: {
    primary: "var(--bkg-primary)",
    secondary: "var(--bkg-secondary)",
  },
  accent: "var(--accent)",
  gray: {
    one: "var(--gray1)",
    two: "var(--gray2)",
    three: "var(--gray3)",
    four: "var(--gray4)",
  },
  text: {
    primary: "var(--text-primary)",
    secondary: "var(--text-secondary)",
  },
  shadow: "var(--shadow)",
};

// pixels approximations for "medium" font sizes
export const SIZES = {
  font: {
    xxl: "1.5em", // 24 px
    xl: "1.375em", // 22 px
    l: "1.25em", // 20 px
    m: "1.125em", // 18 px
    s: "1em", // 16 px
    xs: "0.875em", // 14 px
    xxs: "0.75em", // 12 px
  },
  space: {
    xxl: "2rem",
    xl: "1.5rem",
    l: "1.25rem",
    m: "1rem",
    s: ".75rem",
    xs: ".5rem",
    xxs: ".25rem",
  },
};
