import { createContext, useReducer } from "react";

export const filterContext = createContext();

const initialState = [];
const filterReducer = (state, tag) => {
  // if it's not in the list, add it.
  if (!state.includes(tag)) return [...state, tag];
  // if it's in the list -> filter it out.
  return state.filter((item) => item !== tag);
};

export function FilterProvider({ children }) {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  return (
    <filterContext.Provider value={{ state, dispatch }}>
      {children}
    </filterContext.Provider>
  );
}
