// React libs
import { useState } from "react";
import styled from "styled-components";
// Project libs/dependencies
import useImage from "../../libs/useImage";
import { COLORS, SIZES } from "../../theming/variables";
// Components
import H1 from "../atoms/H1";
import H2 from "../atoms/H2";
// Assets
import ImageNotFound from "../../images/not-found.png";
import IconCollapse from "../../images/icon-collapse.svg";

export default function Experience({ data }) {
  const { img, imgDark, employer, title, dates, description } = data;
  const { image } = useImage(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? imgDark
      : img
  );

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Wrapper>
      <Employer>
        <img src={img ? image : ImageNotFound} alt={employer} />
        <p>{employer}</p>
      </Employer>
      <H2Color>
        <H2 type="experience">{dates}</H2>
      </H2Color>
      <H1 type="experience">{title}</H1>
      <Details>
        <Summary onClick={() => setIsExpanded(!isExpanded)}>
          <p>Responsibilities</p>
          <ToggleButton isExpanded={isExpanded}>
            <img src={IconCollapse} alt="expand/collapse" />
          </ToggleButton>
        </Summary>
        {isExpanded && (
          <Content>
            {description.split("\n").map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </Content>
        )}
      </Details>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-auto-rows: ${SIZES.space.l} ${SIZES.space.l} auto;
  grid-template-areas: "emp dates" "emp title" "emp description";
  gap: ${SIZES.space.xs} ${SIZES.space.m};
  &:not(:last-child) {
    padding-bottom: ${SIZES.space.xs};
    border-bottom: 1px solid ${COLORS.gray.three};
  }
`;

const Employer = styled.div`
  grid-area: emp;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: ${SIZES.font.xxs};
  gap: ${SIZES.space.s};
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    max-height: auto;
  }
`;

const H2Color = styled.div`
  color: ${COLORS.gray.two};
`;

const Details = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Summary = styled.div`
  font-style: italic;
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${SIZES.space.m};
  padding-bottom: ${SIZES.space.xxs};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.ul`
  padding: ${SIZES.space.s};
  padding-left: ${SIZES.space.l};
  li {
    margin-bottom: ${SIZES.space.xs};
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "○";
      color: ${COLORS.accent};
      font-size: ${SIZES.font.xs};
      margin-left: -${SIZES.space.m};
      margin-right: ${SIZES.space.xs};
    }
  }
`;

const ToggleButton = styled.button`
  all: unset;
  transition: all 0.25s ease-in-out;
  transform: ${(props) => (props.isExpanded ? "rotate(90deg)" : "none")};
  display: flex;

  img {
    width: ${SIZES.font.xs};
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;
