// React libs
import styled from "styled-components";
// Project libs/dependencies
import { SIZES } from "../../theming/variables";
import useImage from "../../libs/useImage";
// Components
import H2 from "../atoms/H2";
// Assets
import ImageNotFound from "../../images/not-found.png";

export default function Hobby({ name, description = "", img }) {
  const { image } = useImage(img);

  return (
    <Wrapper>
      <img src={img ? image : ImageNotFound} alt={name} />
      <H2 type="hobby">{name}</H2>
      <p>{description}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SIZES.space.s};
  margin-bottom: ${SIZES.space.s};
  gap: ${SIZES.space.s};

  img {
    border-radius: ${SIZES.space.xs};
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: auto;
    aspect-ratio: 8 / 3;
  }

  h2 {
    margin-top: ${SIZES.space.s};
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
