// React libs
import { useState, useEffect } from "react";
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "./theming/variables";
import DATA from "./data";
// Components
import Header from "./components/molecules/Header";
import Skills from "./components/organisms/Skills";
import Hobbies from "./components/organisms/Hobbies";
import FeaturedArticle from "./components/molecules/FeaturedArticle";
import Experiences from "./components/organisms/Experiences";
import Certifications from "./components/organisms/Certifications";
import Projects from "./components/organisms/Projects";
// Assets
import Avatar from "./images/profilePic.png";

function App() {
  const [isDark, setIsDark] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const setIsDarkState = (event) => {
      setIsDark(event.matches);
    };
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => setIsDarkState(event));
    return () =>
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", (event) => setIsDarkState(event));
  }, []);

  useEffect(() => {
    document.body.style.setProperty(
      "--track",
      isDark ? COLORS.gray.three : COLORS.bkg.primary
    );
    document.body.style.setProperty(
      "--thumb",
      isDark ? COLORS.gray.two : COLORS.gray.three
    );
    document.body.style.setProperty("--thumbHover", COLORS.accent);
  }, [isDark]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header data={DATA.HEADER} img={Avatar} />
      </HeaderWrapper>
      <ProjectsWrapper>
        <Projects data={DATA.PROJECTS} />
      </ProjectsWrapper>
      <Main>
        <Left>
          {DATA.SKILLS.map((skill, idx) => (
            <Skills
              data={skill}
              key={idx}
              expanded={skill.name.toLowerCase() === "development"}
            />
          ))}
          <Hobbies data={DATA.HOBBIES} />
        </Left>
        <Right>
          <FeaturedArticle data={DATA.ARTICLES[0]} />
          <Certifications data={DATA.CERTIFICATIONS} />
          <Experiences data={DATA.EXPERIENCES} />
        </Right>
      </Main>
      <Footer>
        Created by <a href="https://github.com/nosthrillz">NoSThrillZ</a> based
        on a challenge from{" "}
        <a href="https://devchallenges.io/challenges/5ZnOYsSXM24JWnCsNFlt">
          devChallenges.io
        </a>
      </Footer>
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${SIZES.space.xxl};
  padding-bottom: calc(${SIZES.space.m} * 2);

  > * {
    width: min(1000px, 100%);
  }

  @media screen and (max-width: 700px) {
    padding: ${SIZES.space.s};
  }
`;

const HeaderWrapper = styled.div`
  margin-left: ${SIZES.space.xl};
  margin-right: ${SIZES.space.xl};
`;

const Left = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Right = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ProjectsWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.gray.three};
  > * {
    width: min(1000px, 100%);
  }
`;

const Footer = styled.footer`
  display: block;
  margin-top: ${SIZES.space.l};
  font-size: ${SIZES.font.xxs};
  text-align: center;
  @media screen and (max-width: 1000px) {
    margin-top: ${SIZES.space.s};
  }
`;
