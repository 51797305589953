// React libs
import { useState } from "react";
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";
// Components
import Section from "../atoms/Section";
import H1 from "../atoms/H1";
import ProgressBar from "../atoms/ProgressBar";

export default function Skills({ data, expanded }) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <Section
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded(!isExpanded)}
      isExpandable={true}
    >
      <ClickableH1 onClick={() => setIsExpanded(!isExpanded)}>
        <H1 type="skills">{data.name}</H1>
      </ClickableH1>
      <Wrapper>
        {data.data.map((item, idx) => (
          <Skill key={idx} progress={item.progress}>
            <p>{item.name}</p>
            <ProgressBar progress={item.progress} />
          </Skill>
        ))}
      </Wrapper>
    </Section>
  );
}

const generateShadow = (color) => {
  return `1px 1px ${color}, -1px -1px ${color}, -1px 0 ${color}, 0 -1px ${color}, 0 1px ${color}, 1px 0 ${color}, -1px 1px ${color}, 1px -1px ${color}`;
};

const ClickableH1 = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.s};
`;

const Skill = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${SIZES.space.xxs};
  position: relative;

  &:hover {
    cursor: help;
  }

  &:hover div:before {
    content: ${(props) => `'${props?.progress}%'`};
    color: ${COLORS.text.primary};
    font-size: ${SIZES.font.xxs};
    text-shadow: ${generateShadow(COLORS.bkg.primary)};
    line-height: 1;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
`;
