// React libs
import styled from "styled-components";
// Project libs/dependencies
import useImage from "../../libs/useImage";
import { COLORS, SIZES } from "../../theming/variables";
// Components
import H1 from "../atoms/H1";
import H2 from "../atoms/H2";
// Assets
import ImageNotFound from "../../images/not-found.png";

export default function Certification({ data }) {
  const { img, imgDark, date, institution, certification, links } = data;
  const { image } = useImage(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? imgDark
      : img
  );

  return (
    <Wrapper>
      <Institution>
        <img src={img ? image : ImageNotFound} alt={institution} />
        <p>{institution}</p>
      </Institution>
      <H2Color>
        <H2 type="certification">{date}</H2>
      </H2Color>
      <H1 type="certification">
        <Link href={links.site.url} target="_blank" rel="noreferrer">
          {certification}
        </Link>
      </H1>
      <a href={links.certification.url} target="_blank" rel="noreferrer">
        {links.certification.name}
      </a>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-auto-rows: auto;
  grid-template-areas: "cert dates" "cert title" "cert description";
  gap: ${SIZES.space.xs} ${SIZES.space.m};
  &:not(:last-child) {
    padding-bottom: ${SIZES.space.xs};
    border-bottom: 1px solid ${COLORS.gray.three};
  }
`;

const Institution = styled.div`
  grid-area: cert;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${SIZES.font.xxs};
  gap: ${SIZES.space.s};
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    max-height: auto;
  }
`;

const H2Color = styled.div`
  color: ${COLORS.gray.two};
`;

const Link = styled.a`
  color: ${COLORS.text.primary};
  border-bottom: 1px solid;
  &:hover {
    color: ${COLORS.accent};
  }
`;
