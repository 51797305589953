// React libs
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";

export default function Button({
  text = "Button",
  size = "sm",
  square,
  active,
  onClick,
  disabled,
  link,
}) {
  const Component = !!link ? BtnLink : Btn;
  return (
    <Component
      size={size}
      active={active}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      square={square}
      href={link}
      target={!!link ? "_blank" : undefined}
      rel={!!link ? "noreferrer" : undefined}
    >
      {text}
    </Component>
  );
}

const Btn = styled.button`
  all: unset;
  text-align: center;
  border: 1px solid;
  border-color: ${(props) =>
    props.active ? COLORS.accent : COLORS.text.primary};
  border-radius: ${(props) => (props.square ? SIZES.space.xxs : SIZES.space.s)};
  padding: ${(props) =>
    props.size === "sm"
      ? `${SIZES.space.xs} ${SIZES.space.s}`
      : `${SIZES.space.s} ${SIZES.space.m}`};
  margin: ${SIZES.space.xxs};
  font-size: ${(props) => (props.size === "sm" ? SIZES.font.xs : SIZES.font.s)};
  background: ${(props) =>
    props.active ? COLORS.accent : COLORS.bkg.secondary};
  color: ${(props) => (props.active ? "white" : COLORS.text.primary)};

  &:hover {
    background: ${(props) => !props.disabled && COLORS.accent};
    color: ${(props) => !props.disabled && "white"};
    border-color: ${(props) => !props.disabled && COLORS.accent};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: 0.7;
  }
`;

const BtnLink = styled.a`
  all: unset;
  text-align: center;
  border: 1px solid;
  border-color: ${(props) =>
    props.active ? COLORS.accent : COLORS.text.primary};
  border-radius: ${(props) => (props.square ? SIZES.space.xxs : SIZES.space.s)};
  padding: ${(props) =>
    props.size === "sm"
      ? `${SIZES.space.xs} ${SIZES.space.s}`
      : `${SIZES.space.s} ${SIZES.space.m}`};
  margin: ${SIZES.space.xxs};
  font-size: ${(props) => (props.size === "sm" ? SIZES.font.xs : SIZES.font.s)};
  background: ${(props) =>
    props.active ? COLORS.accent : COLORS.bkg.secondary};
  color: ${(props) => (props.active ? "white" : COLORS.text.primary)};

  &:hover {
    background: ${(props) => !props.disabled && COLORS.accent};
    color: ${(props) => !props.disabled && "white"};
    border-color: ${(props) => !props.disabled && COLORS.accent};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: 0.7;
  }
`;
