const SKILLS = [
  {
    name: "Authoring",
    data: [
      {
        progress: 90,
        name: "Markdown",
      },
      {
        progress: 100,
        name: "Microsoft Office",
      },
      {
        progress: 100,
        name: "Google Workspace",
      },
      {
        progress: 90,
        name: "Adobe TCS",
      },
      {
        progress: 80,
        name: "Help+Manual",
      },
      {
        progress: 20,
        name: "Zendesk",
      },
    ],
  },
  {
    name: "Development",
    data: [
      {
        progress: 90,
        name: "HTML",
      },
      {
        progress: 75,
        name: "CSS & SCSS",
      },
      {
        progress: 75,
        name: "JS & ES6",
      },
      {
        progress: 80,
        name: "React.js",
      },
      {
        progress: 25,
        name: "Node.js",
      },
      {
        progress: 30,
        name: "Next.js",
      },
      {
        progress: 40,
        name: "Redux & RTK",
      },
      {
        progress: 30,
        name: "Webpack",
      },
      {
        progress: 50,
        name: "Git",
      },
    ],
  },
  {
    name: "Design",
    data: [
      {
        progress: 100,
        name: "Adobe Photoshop",
      },
      {
        progress: 50,
        name: "Adobe Premiere",
      },
      {
        progress: 70,
        name: "Adobe Illustrator",
      },
      {
        progress: 50,
        name: "CorelDRAW",
      },
      {
        progress: 70,
        name: "LucidChart",
      },
      {
        progress: 60,
        name: "Invision",
      },
      {
        progress: 40,
        name: "Figma",
      },
    ],
  },
];

const HOBBIES = [
  {
    name: "Snowboarding",
    image: "snowboard.png",
    description:
      "I love winter and the mountains and snowboarding is my all-time favorite sport. ",
  },
  {
    name: "Piano",
    image: "piano.jpeg",
    description:
      "Piano is my one true love in terms of instruments. I'm learning!",
  },
  {
    name: "Guitar",
    image: "guitar.jpeg",
    description:
      "The guitar's my go-to instrument whenever I feel like playing something.",
  },
  {
    name: "Singing",
    image: "singing.jpeg",
    description: "I love signing. Hobby bands, festivals, karaoke... wherever.",
  },
  {
    name: "Gaming",
    image: "gaming.jpeg",
    description:
      "My passion from computers stems, in part, from gaming. Favorites: Mass Effect, DotA.",
  },
  {
    name: "Drawing",
    image: "drawing.jpeg",
    description:
      "I started drawing 15 years ago, when I fell in loce with pencil-drawn hyper-realism.",
  },
  {
    name: "Painting",
    image: "painting.jpeg",
    description:
      "I'm new to painting, but I've drawn quite a lot. I want to paint with the same level of detail I can draw on paper.",
  },
  {
    name: "Table tennis",
    image: "tabletennis.jpeg",
    description:
      "I picked this up in 2018 and I find it entertaining and healthy :)",
  },
  {
    name: "Pool",
    image: "pool.jpeg",
    description:
      "I was never great, but I've always enjoyed shooting some pool with friends or colleagues.",
  },
  {
    name: "Rollerblading",
    image: "rollerblades.jpeg",
    description:
      "I love them so much, that in July 2019, I skated 150 km by commuting to work on rollerskates.",
  },
  {
    name: "Badminton/Squash",
    image: "badminton.jpg",
    description:
      "We discovered Badminton when we moved to Zürich. Back home we used to play Squash ^_^",
  },
  {
    name: "Biking",
    image: "biking.jpeg",
    description:
      "I'm not a runner, but I like biking! My plan is to commute to work on a bike.",
  },
  {
    name: "Hiking",
    image: "hiking.jpeg",
    description:
      "Since I love the mountains so much, I also like to occasionally go on hikes with friends.",
  },
  {
    name: "Ice skating",
    image: "iceskating.png",
    description:
      "As a child, I used to play hockey. As an adult, I can barely ice skate anymore...",
  },
  {
    name: "Traveling",
    image: "travel.jpeg",
    description:
      "I love to travel and discover new places, people, and cultures.",
  },
  {
    name: "Dancing",
    image: "dancing.jpeg",
    description:
      "I danced my way through school and university. Big fan of street dance & breakdance.",
  },
  {
    name: "Snorkeling",
    image: "snorkeling.jpeg",
    description:
      "I discovered snorkeling while on vacation in Greece. I never knew it's so much fun!",
  },
];

// first article is featured
const ARTICLES = [
  {
    img: "https://miro.medium.com/max/1056/0*USGeC8iD_c6dgGBp",
    title: "A different way of thinking of React props",
    description:
      "As a web development enthusiast, I started learning React.js bit by bit. But I distinctly remember how hard it was to wrap my head around props. The theory is simple: Props are properties passed to child components.\nBut in practice, it gets complicated fast once you start adding more components.\nIn this article, I’m laying out what helped me understand props and how I manage new projects so I don’t lose my mind.",
    link: {
      href: "https://medium.com/@ilie06/a-different-way-of-thinking-of-react-props-cb473ff8fb6f",
      name: "medium.com",
    },
  },
];

const EXPERIENCES = [
  {
    img: "logo-google.png",
    imgDark: "logo-google.png",
    employer: "Google Switzerland",
    title: "Technical writer",
    dates: "Apr 2020 - present",
    description:
      "Identify documentation pain points and scope out high-quality work that addresses user needs;\nWrite content plans, gain consensus, track progress, and lead execution to improve documentation;\nCoordinate and collaborate with subject-matter experts, leads, product managers, and software engineers.",
  },
  {
    img: "logo-ownzones.png",
    imgDark: "logo-ownzones-dark.png",
    employer: "OWNZONES (Ateliere)",
    title: "Technical writer",
    dates: "Mar 2018 - Mar 2020",
    description:
      "Identify doc needs, plan, write, and maintain documentation on target platforms;\nSupport teams with information architecture, presentations, and ideation on various topics.",
  },
  {
    img: "logo-netop.png",
    imgDark: "logo-netop.png",
    employer: "Netop",
    title: "Technical writer",
    dates: "Jun 2016 - Mar 2018",
    description:
      "Own written, video, and audio documentation for two product streams;\nImprove the existing documentation set and support other departments/teams;\nRevamp and update outdated content, setting procedures in place.",
  },
  {
    img: "logo-misys.png",
    imgDark: "logo-misys-dark.png",
    employer: "Misys (Finastra)",
    title: "Technical writer",
    dates: "Mar 2014 - Jun 2016",
    description:
      "Establish quality standards, edit and review training videos, video guides, and tutorials;\nWrite documentation and release notes for multiple software streams;\nOwn and execute internal graphic design requirements",
  },
];

const HEADER = {
  name: "Ilie Bogdan-Mihai",
  title: "Technical writer | Front-end web developer",
  description:
    "I built a career in Technical Writing by being a user advocate, writing for various audiences in diverse technical environments.\nDesign-oriented, I believe in offering great user experiences, and my curiosity led me to discover how much more I can accomplish through web development.\nAfter self-studying for over a year, I’m ready to change careers and start developing web apps with the same user focus, but with even more passion. Aside from being a fast learner, I also bring to the table plenty experience in researching, planning, negotiation, and conflict resolution.",
  img: "",
  email: "ilie06@gmail.com",
  phone: "+ 41 765 88 28 47",
};

const Tech = {
  react: "React.js",
  redux: "Redux",
  rtk: "RTK",
  css: "CSS",
  cssmodules: "CSS modules",
  scss: "SCSS",
  styled: "styled-components",
  html: "HTML",
  js: "JavaScript",
  next: "Next.js",
  ts: "TypeScript",
};

const CERTIFICATIONS = [
  {
    img: "logo-cssjs.png",
    imgDark: "logo-cssjs.png",
    date: "2021",
    institution: "Josh W Comeau",
    certification: "CSS for JS Developers",
    links: {
      certification: {
        url: "https://drive.google.com/file/d/1ibn8iAlt6CUSApb0IRIOm0SxtwbI_ubl/view",
        name: "Certificate of completion",
      },
      site: {
        url: "https://css-for-js.com/",
        name: "css-for-js.com",
      },
    },
  },
  {
    img: "logo-udacity.png",
    imgDark: "logo-udacity-dark.png",
    date: "2020",
    institution: "Udacity",
    certification: "Front End Web Developer Nanodegree",
    links: {
      certification: {
        url: "https://confirm.udacity.com/DEEUW64L",
        name: "Certificate confirmation",
      },
      site: {
        url: "https://www.udacity.com/course/front-end-web-developer-nanodegree--nd0011",
        name: "udacity.com",
      },
    },
  },
];

const PROJECTS = [
  {
    name: "Quizzountry - Countries quiz app",
    description:
      "This is a solution to the <url==https://devchallenges.io/challenges/Bu3G2irnaXmfwQ8sZkw8 text==Country quiz url/> from devChallenge.io.\nIt is a country quiz game, which I built with TypeScript as a first-time TS experience.",
    tags: [Tech.react, Tech.ts, Tech.scss, Tech.styled],
    img: "proj-quiz.png",
    links: {
      code: "https://github.com/nosthrillz/quizzountry",
      demo: "https://quizzountry.vercel.app",
    },
  },
  {
    name: "Vremea - weather forecast",
    description:
      "This is a solution to the <url==https://devchallenges.io/challenges/mM1UIenRhK808W8qmLWv text==Weather forecast url/> from devChallenge.io.\nIt is a two-themed weather forecast (prefers-color-scheme), localized in 3 languages (EN, RO, DE) based on user preference. You can see the weather forecast for the next 5 days for the current location or you can search for a city. Data is provided by metaweather.com.",
    tags: [Tech.react, Tech.scss, Tech.styled],
    img: "proj-vremea.png",
    links: {
      code: "https://github.com/nosthrillz/vremea",
      demo: "https://vremea.vercel.app",
    },
  },
  {
    name: "Rock, paper, scissors, lizard, spock",
    description:
      "This is a solution to the <url==https://www.frontendmentor.io/challenges/rock-paper-scissors-game-pTgwgvgH text==Rock, paper, scissors, lizard, spock challenge url/> from Frontend Mentor.\nIt is the classic rock, paper, scissors game + the extended lizard, spock version. It was build according to the challenge specs.",
    tags: [Tech.react, Tech.scss, Tech.styled],
    img: "proj-rock-paper-scissors.png",
    links: {
      code: "https://github.com/nosthrillz/frontend-mentor-rock-paper-scissors",
      demo: "https://rock-paper-scissors-nosthrillz.vercel.app",
    },
  },
  {
    name: "Multi-themed Calculator",
    description:
      "This is a solution to the <url==https://www.frontendmentor.io/challenges/calculator-app-9lteq5N29 text==Calculator app challenge url/> from Frontend Mentor.\nIt is a 3-themed calculator app using React, Context, and useReducer.",
    tags: [Tech.react, Tech.scss, Tech.cssmodules],
    img: "proj-calculator.png",
    links: {
      code: "https://github.com/nosthrillz/frontend-mentor-calculator",
      demo: "http://3t-calculator.vercel.app",
    },
  },
  {
    name: "Country explorer",
    description:
      "This is a solution to the <url==https://www.frontendmentor.io/challenges/rest-countries-api-with-color-theme-switcher-5cacc469fec04111f7b848ca text==REST Countries API with color theme switcher challenge url/> from Frontend Mentor.\nIt is a two-themed statically-generated site with dynamic routes for each country fetched from the API.",
    tags: [Tech.react, Tech.next, Tech.scss, Tech.cssmodules],
    img: "proj-countries.png",
    links: {
      code: "https://github.com/nosthrillz/frontend-mentor-country-explorer",
      demo: "http://country-explorer-ten.vercel.app",
    },
  },
  {
    name: "Tip splitter",
    description:
      "This is a solution to the <url==https://www.frontendmentor.io/challenges/tip-calculator-app-ugJNGbJUX text==Tip splitter challenge url/> from Frontend Mentor.\nThe challenge was to build a tip splitter calculator according to the design.",
    tags: [Tech.react, Tech.redux, Tech.rtk, Tech.scss, Tech.cssmodules],
    img: "proj-splitty.png",
    links: {
      code: "https://github.com/nosthrillz/frontend-mentor-tip-splitter",
      demo: "http://tip-splitty.vercel.app",
    },
  },
  {
    name: "Glassmorphism web components",
    description:
      "I wanted to touch on web components. I was inspired by the numerous glassmorphism UIs I've seen on dev.two, so I gave it a go.\nI used a very colorful background to highlight the glassy properties of the cards.",
    tags: [Tech.html, Tech.css, Tech.js],
    img: "proj-glass.png",
    links: {
      code: "https://github.com/nosthrillz/glass-web-components-demo",
      demo: "https://glass-web-components-demo.vercel.app/",
    },
  },
  {
    name: "Time tracking dashboard",
    description:
      "This is a solution to the <url==https://www.frontendmentor.io/challenges/time-tracking-dashboard-UIQ7167Jw text==Time tracking dashboard challenge url/> from Frontend Mentor.\nThe challenge is to build out this dashboard and get it looking as close to the design as possible.",
    tags: [Tech.html, Tech.css, Tech.react],
    img: "proj-time-tracker.png",
    links: {
      code: "https://github.com/nosthrillz/frontend-mentor-time-tracking-dashboard",
      demo: "http://frontend-mentor-time-tracking-dashboard-eight.vercel.app",
    },
  },
  {
    name: "Carty",
    description:
      "Carty is a simple React + Redux Toolkit project.\nIt presents users with a list of products that they can add to the cart and then affect quantities and cart contents.",
    tags: [Tech.react, Tech.redux, Tech.rtk, Tech.scss],
    img: "proj-carty.png",
    links: {
      code: "https://github.com/nosthrillz/carty",
      demo: "https://carty.vercel.app",
    },
  },
];

const DATA = {
  SKILLS,
  HOBBIES,
  ARTICLES,
  EXPERIENCES,
  HEADER,
  PROJECTS,
  CERTIFICATIONS,
};

export default DATA;
