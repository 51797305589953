// React libs
import styled from "styled-components";
// Project libs/dependencies
import useImage from "../../libs/useImage";
import { COLORS, SIZES } from "../../theming/variables";
// Components
import H1 from "../atoms/H1";
import Section from "../atoms/Section";
// Assets
import ImageNotFound from "../../images/not-found.png";

export default function FeaturedArticle({ data }) {
  const { img, title, description, link } = data;
  const { image } = useImage(img);

  return (
    <Section>
      <Title>Article</Title>
      <ArtWrapper>
        <Header>
          <H1 type="articles">{title}</H1>
          <img src={img ? img : image ? image : ImageNotFound} alt="article" />
        </Header>
        <Content>
          {description.split("\n").map((line, idx) => (
            <p key={idx}>{line}</p>
          ))}
        </Content>
      </ArtWrapper>
      <div>
        Read it on{" "}
        <a href={link.href} target="_blank" rel="noreferrer">
          {link.name}
        </a>
      </div>
    </Section>
  );
}

const Title = styled.p`
  color: ${COLORS.gray.two};
  margin-bottom: ${SIZES.space.xs};
`;

const ArtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.xl};
`;

const Header = styled.div`
  display: flex;
  gap: ${SIZES.space.s};
  margin-top: -${SIZES.space.xl};
  align-items: center;

  h1 {
  }

  img {
    width: 50%;
    height: auto;
    margin-right: -${SIZES.space.xl};
    border-radius: ${SIZES.space.s} 0 0 ${SIZES.space.s};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.l};
`;
