// React libs
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";
// Components
import H1 from "../atoms/H1";
import H2 from "../atoms/H2";
import Section from "../atoms/Section";
// Assets
import ImageNotFound from "../../images/not-found.png";
import IconEmail from "../../images/icon-email.svg";
import IconPhone from "../../images/icon-phone.svg";

export default function Header({ data, img }) {
  const { name, title, description, email, phone } = data;

  return (
    <Section>
      <Wrapper>
        <img src={img ? img : ImageNotFound} alt={name} />
        <Content>
          <PersonDetails>
            <Title>
              <H1 type="header">{name}</H1>
              <H2 type="header">{title}</H2>
            </Title>
            <Contact>
              <img src={IconEmail} alt="email address" />
              <a href="mailto:ilie06@gmail.com">{email}</a>
              <img src={IconPhone} alt="phone number" />
              <a href="tel:+41765882847">{phone}</a>
            </Contact>
          </PersonDetails>
          <Description>
            {description.split("\n").map((line, idx) => (
              <p key={idx}>{line}</p>
            ))}
          </Description>
        </Content>
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: ${SIZES.space.xl};
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    flex: 1;
    width: 200px;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center top;
    border-radius: ${SIZES.space.s};
    aspect-ratio: 1 / 1;
  }
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.xl};
`;

const PersonDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${SIZES.space.s};
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.xs};

  h2 {
    color: ${COLORS.text.secondary};
  }

  @media screen and (max-width: 1000px) {
    justify-content: center;
    text-align: center;
  }
`;

const Contact = styled.div`
  display: grid;
  grid-template: repeat(2, auto) / 30px auto;
  gap: ${SIZES.space.m} ${SIZES.space.xs};
  align-items: center;

  img {
    width: 16px;
    height: auto;
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: var(--accent);

    &:hover {
      color: var(--accent);
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.l};
`;
