// React libs
import { useState } from "react";
import styled from "styled-components";
// Components
import Section from "../atoms/Section";
import H1 from "../atoms/H1";
import Hobby from "../molecules/Hobby";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";

export default function Hobbies({ data }) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Section
      scrollbarPadding="4px"
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded(!isExpanded)}
      isExpandable={true}
    >
      <ClickableH1 onClick={() => setIsExpanded(!isExpanded)}>
        <H1>Hobbies</H1>
      </ClickableH1>
      <ColumnFlex>
        {data.map((item, idx) => (
          <Hobby
            key={idx}
            name={item.name}
            img={item.image}
            description={item.description}
          />
        ))}
      </ColumnFlex>
      {isExpanded && <OverflowMask />}
    </Section>
  );
}

const ClickableH1 = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.m};
  overflow-y: scroll;
  position: relative;
  padding-bottom: calc(${SIZES.space.xxl} * 2);
  padding-right: 4px;
  /* height: 615px; */
  height: calc(${SIZES.space.xs} * 77);
  @media screen and (max-width: 700px) {
    height: calc(${SIZES.space.xs} * 40);
  }
`;

const OverflowMask = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    ${COLORS.bkg.secondary} 0%,
    ${COLORS.bkg.secondary} 20%,
    transparent 100%
  );
`;
