// React libs
import styled from "styled-components";
// Project libs/dependencies
import { SIZES } from "../../theming/variables";
import useImage from "../../libs/useImage";
// Components
import H1 from "../atoms/H1";
import H2 from "../atoms/H2";
import Button from "../atoms/Button";
import Section from "../atoms/Section";
// Assets
import ImageNotFound from "../../images/not-found.png";

export default function Project({ data }) {
  const { name, description, tags, img, links } = data;
  const { image } = useImage(img);

  const parsedDescription = () => {
    const regExp = /(.+)<url==(.+)text==(.+)url\/>(.+)/g;
    const lines = description.split("\n");
    return lines.map((element, idx) => {
      const match = regExp.exec(element);
      if (!match) return <p key={idx}>{element}</p>;
      return (
        <p key={idx}>
          {match[1]}
          <a href={match[2]}>{match[3]}</a>
          {match[4]}
        </p>
      );
    });
  };

  return (
    <Section>
      <Grid>
        <a href={links.demo} target="_blank" rel="noreferrer">
          <ProjectImage
            src={img ? image : ImageNotFound}
            alt={name + " screenshot"}
          />
        </a>
        <H2 type="projects">{tags.map((tag) => `#${tag}`).join(" ")}</H2>
        <H1>{data?.name}</H1>
        <Description>{parsedDescription()}</Description>
        <Buttons>
          <Button active text="Demo" link={links.demo} />
          <Button text="Code" link={links.code} />
        </Buttons>
      </Grid>
    </Section>
  );
}

const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto) 1fr auto;
  gap: ${SIZES.space.s};
`;

const ProjectImage = styled.img`
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  border-radius: ${SIZES.space.s};
  object-fit: cover;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.xs};
`;

const Buttons = styled.div`
  display: flex;
  > * {
    flex: 1;
  }
`;
