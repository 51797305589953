// React libs
import styled from "styled-components";
// Project libs/dependencies
import { SIZES } from "../../theming/variables";

const h1Types = {
  header: {
    weight: "600",
    style: "normal",
    size: SIZES.font.xxl,
    transform: "none",
  },
  articles: {
    weight: "500",
    style: "normal",
    size: SIZES.font.xxl,
    transform: "none",
  },
  filter: {
    weight: "500",
    style: "normal",
    size: SIZES.font.m,
    transform: "none",
  },
  skills: {
    weight: "700",
    style: "normal",
    size: SIZES.font.m,
    transform: "uppercase",
  },
  default: {
    weight: "500",
    style: "normal",
    size: SIZES.font.xxl,
    transform: "none",
  },
  experience: {
    weight: "600",
    style: "normal",
    size: SIZES.font.s,
    transform: "none",
  },
  certification: {
    weight: "600",
    style: "normal",
    size: SIZES.font.s,
    transform: "none",
  },
};

export default function H1(props) {
  const { children, type = "default" } = props;

  return <Heading1 type={h1Types[type]}>{children}</Heading1>;
}

const Heading1 = styled.h1`
  font-weight: ${(props) => props.type.weight};
  font-style: ${(props) => props.type.style};
  text-transform: ${(props) => props.type.transform};
  font-size: ${(props) => props.type.size};
`;
