// React libs
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
// Project libs/dependencies
import { filterContext } from "../../libs/filterContext.js";
import { SIZES } from "../../theming/variables.js";
// Components
import Button from "../atoms/Button.js";
import Filters from "../molecules/Filters.js";
import Project from "../molecules/Project.js";

export default function Projects({ data }) {
  const tags = useMemo(
    () =>
      data
        .map((item) => item.tags)
        .reduce((prev, curr) => [...prev, ...curr])
        .sort()
        .filter(function (item, pos, arr) {
          return !pos || item !== arr[pos - 1];
        }),
    [data]
  );

  const filterCtx = useContext(filterContext);
  const filters = filterCtx.state;

  const [filteredProjects, setFilteredProjects] = useState(data);
  const [page, setPage] = useState(0);
  const PAGES_STEP = 3;
  const pageCount = useMemo(
    () => Math.ceil(filteredProjects.length / 3),
    [filteredProjects]
  );
  const pages = [...Array(pageCount).keys()];

  useEffect(() => {
    !!filters.length
      ? setFilteredProjects(
          data.filter((project) =>
            filters.some((item) => project.tags.includes(item))
          )
        )
      : setFilteredProjects(data);
  }, [filters, data]);
  return (
    <div>
      <Filters tags={tags} number={filteredProjects.length} />
      <ProjectsWrapper>
        {filteredProjects.map(
          (project, idx) =>
            idx >= page &&
            idx <= page + PAGES_STEP - 1 && <Project key={idx} data={project} />
        )}
      </ProjectsWrapper>
      <Pagination>
        <Button
          square
          text="<"
          onClick={() => setPage(page - PAGES_STEP)}
          disabled={!page}
        />

        {pages.map((number, idx) => (
          <Button
            square
            text={number + 1}
            key={idx}
            onClick={() => setPage(number * PAGES_STEP)}
            active={page === number * PAGES_STEP}
          />
        ))}

        <Button
          square
          text=">"
          onClick={() => setPage(page + PAGES_STEP)}
          disabled={page + 1 > pageCount + PAGES_STEP}
        />
      </Pagination>
    </div>
  );
}

const ProjectsWrapper = styled.div`
  display: flex;
  > * {
    flex: 1;
    max-width: 50%;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    > * {
      max-width: revert;
    }
  }
`;

const Pagination = styled.div`
  padding: ${SIZES.space.s};
  display: flex;
  justify-content: flex-end;
`;
