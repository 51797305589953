// React libs
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";
// Assets
import IconCollapse from "../../images/icon-collapse.svg";

export default function Section({
  children,
  isExpanded,
  onToggle,
  isExpandable = false,
  scrollbarPadding,
}) {
  return (
    <Wrapper
      scrollbarPadding={scrollbarPadding}
      isExpanded={isExpanded}
      isExpandable={isExpandable}
    >
      {isExpandable && (
        <ToggleButton onClick={onToggle} isExpanded={isExpanded}>
          <img src={IconCollapse} alt="expand/collapse" />
        </ToggleButton>
      )}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin: ${SIZES.space.m};
  padding: ${SIZES.space.xl};
  padding-right: ${(props) =>
    props.scrollbarPadding ? props.scrollbarPadding : SIZES.space.xl};
  box-shadow: 0 30px 10px -25px ${COLORS.shadow};
  border-radius: ${SIZES.space.s};
  background: ${COLORS.bkg.secondary};
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.xl};
  overflow: hidden;
  position: relative;
  max-height: ${(props) =>
    props.isExpandable
      ? !props.isExpanded && `calc(${SIZES.space.xl} * 3)`
      : "initial"};

  @media screen and (max-width: 1000px) {
    margin: ${SIZES.space.s};
    padding: ${SIZES.space.l};
    max-height: ${(props) =>
      props.isExpandable
        ? !props.isExpanded && `calc(${SIZES.space.l} * 3)`
        : "initial"};
  }
`;

const ToggleButton = styled.button`
  all: unset;
  position: absolute;
  right: ${SIZES.space.xl};
  transition: all 0.25s ease-in-out;
  transform: ${(props) => props.isExpanded && "rotate(90deg)"};

  img {
    width: ${SIZES.font.m};
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;
