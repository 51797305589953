// React libs
import styled from "styled-components";
// Project libs/dependencies
import { COLORS, SIZES } from "../../theming/variables";

const h2Types = {
  header: {
    weight: "500",
    style: "normal",
    size: SIZES.font.m,
    color: COLORS.text.secondary,
  },
  hobby: {
    weight: "600",
    style: "normal",
    size: SIZES.font.m,
  },
  experience: {
    weight: "500",
    style: "normal",
    size: SIZES.font.s,
  },
  certification: {
    weight: "500",
    style: "normal",
    size: SIZES.font.s,
  },
  projects: {
    weight: "500",
    style: "normal",
    size: SIZES.font.s,
  },
};

export default function H2(props) {
  const { children, type } = props;

  return <Heading2 type={h2Types[type]}>{children}</Heading2>;
}

const Heading2 = styled.h2`
  font-weight: ${(props) => props.type.weight};
  font-style: ${(props) => props.type.style};
  text-transform: ${(props) => props.type.transform};
  font-size: ${(props) => props.type.size};
`;
