// React libs
import { useContext } from "react";
import styled from "styled-components";
// Project libs/dependencies
import { SIZES } from "../../theming/variables";
import { filterContext } from "../../libs/filterContext";
// Components
import H1 from "../atoms/H1";
import Section from "../atoms/Section";
import Button from "../atoms/Button";

export default function Filters({ number, tags }) {
  const filterCtx = useContext(filterContext);

  return (
    <Section>
      <Content>
        <H1 type="filter">Projects ({number})</H1>
        <FilterWrapper>
          {tags.map((tag, idx) => (
            <Button
              key={idx}
              text={tag}
              onClick={() => filterCtx.dispatch(tag)}
              active={filterCtx.state.includes(tag)}
            />
          ))}
        </FilterWrapper>
      </Content>
    </Section>
  );
}

const FilterWrapper = styled.div`
  display: flex;
  gap: ${SIZES.space.xxs};
  flex-wrap: wrap;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.space.s};
`;
